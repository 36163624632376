import styled from 'styled-components';

import vars from 'variables';
import achievements from 'assets/HODLForward/achievements.jpg';

import copy from 'assets/HODLForward/copy.svg';
import check from 'assets/HODLForward/check.svg';

const Root = styled.div`
  h1 {
    text-align: center;
  }

  .intro {
    color: ${vars.colors.grey};
    text-align: center;
  }

  img.main {
    width: 100%;
  }

  .info {
    align-items: center;
    position: relative;
  }

  .info p a {
    color: ${vars.colors.blue};
    font-weight: bold;
  }

  .wrapper label {
    font-weight: bold;
    text-transform: uppercase;
    display: block;
  }

  .wrapper .address {
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.5px;
    background: url(${copy}) ${vars.colors.marineBlue} no-repeat center right 9px;
  }

  .wrapper .address.copied {
    background-image: url(${check});
  }

  .achievements {
    text-align: center;
    position: absolute;
    background: url(${achievements}) no-repeat;
    background-size: cover;
    border-radius: 10px;
  }

  .achievements h4 {
    padding-bottom: 5px;
    font-size: 2.1rem;
    font-weight: 900;
    letter-spacing: 1px;
  }

  .achievements strong {
    text-transform: uppercase;
  }

  .tooltip {
    max-width: 365px;
    padding: 16px 12px 12px 18px;
    line-height: 175%;
    background-color: ${vars.colors.marineBlue};
    border-radius: 10px;
    opacity: 1 !important;
    &.place-top {
      &:after {
        border-top-color: ${vars.colors.marineBlue};
      }
    }
    &.place-right {
      &:after {
        border-right-color: ${vars.colors.marineBlue};
      }
    }
    &.place-bottom {
      &:after {
        border-bottom-color: ${vars.colors.marineBlue};
      }
    }
    &.place-left {
      &:after {
        border-left-color: ${vars.colors.marineBlue};
      }
    }
  }

  ${vars.desktop.mediaQuery} {
    h1 {
      margin-top: 100px;
    }

    .intro {
      padding: 0 290px;
      font-size: 1rem;
    }

    img.main {
      margin-top: 100px;
      border-radius: 10px;
    }

    .info {
      margin-top: 10px;
      display: flex;
    }

    .info h2 {
      margin: 0 0 -18px;
      font-size: 2.75rem;
      line-height: 115%;
    }

    .info p {
      margin: 30px 0;
      color: ${vars.colors.grey};
      line-height: 165%;
    }

    .wrapper {
      margin: 0 120px 90px 0;
    }

    .wrapper label {
      font-size: 0.8rem;
    }

    .wrapper .address {
      margin: 15px 0 27px 0;
      padding: 15px 41px 15px 18px;
      cursor: pointer;
      font-size: 0.8rem;
      background-position: center right 13px;
      border-radius: 10px;
    }

    .wrapper a.button {
      margin-right: 18px;
    }

    .info img {
      width: 40%;
      height: 40%;
      border-radius: 20px;
    }

    .achievements {
      bottom: 50px;
      left: calc(50% - 40px);
      padding: 30px 40px 25px 40px;
    }

    .achievements strong {
      font-size: 1.2rem;
    }

    .join-the-communities {
      margin-top: 115px;
    }
  }

  ${vars.mobile.mediaQuery} {
    h1 {
      margin-top: 50px;
      margin-bottom: 15px;
      font-size: 1.75rem;
    }

    img.main {
      height: 245px;
      margin-top: 50px;
      object-fit: cover;
      border-radius: 12px;
    }

    .info {
      margin-top: 6px;
      margin-bottom: 100px;
    }

    .info h2 {
      padding-top: 4px;
      padding-bottom: 8px;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 140%;
    }

    .info p {
      margin-bottom: 25px;
    }

    .wrapper label {
      font-size: 0.6rem;
    }

    .wrapper .address {
      margin: 6px 0 27px 0;
      padding: 10px 30px 8px 12px;
      font-size: 0.6rem;
      background-size: 13px;
      border-radius: 6px;
    }

    .wrapper a.button {
      margin-right: 10px;
      padding-left: 32px;
      padding-right: 32px;
    }

    .info img {
      margin-top: 30px;
      width: 100%;
      border-radius: 10px;
    }

    .achievements {
      left: 20px;
      right: 20px;
      bottom: -20px;
      padding: 23px 0 16px;
    }

    .achievements h4 {
      font-size: 1.55rem;
      line-height: 110%;
    }

    .achievements strong {
      font-size: 0.85rem;
    }

    .join-the-communities {
      display: none;
    }
  }
`;

export { Root };
