import React from "react";
import { Root } from "./style";

import { Link } from "gatsby";

import chunk from "lodash/chunk";

import Paginator from "components/Common/Paginator";
import SiteImage from "components/Common/SiteImage";

import { toResourceURL } from "utils";
import vars from "variables";

const Page = ({ collection, page, perPage, pageIndex }) => {
  const resourceURL = toResourceURL(vars.pages.hodlForward);
  const pages = chunk(collection, perPage).map((group) => ({
    featured: group.shift(),
    items: group,
  }));

  pageIndex || (pageIndex = 1);
  const { featured, items } = pages[pageIndex - 1];

  return (
    <Root>
      <div className="featured">
        <Link to={resourceURL(featured)}>
          <SiteImage image={featured.image} />
        </Link>
        <div>
          <h2>
            <Link to={resourceURL(featured)}>{featured.title}</Link>
          </h2>
          <p>{featured.intro}</p>
          <Link className="desktop button blue" to={resourceURL(featured)}>
            Learn more
          </Link>
          <Link className="mobile more" to={resourceURL(featured)}>
            Learn more
          </Link>
        </div>
      </div>
      <ul>
        {items.map((hodlForwardCase) => (
          <li key={hodlForwardCase.id}>
            <Link to={resourceURL(hodlForwardCase)}>
              <SiteImage image={hodlForwardCase.image} />
            </Link>
            <strong>
              <Link to={resourceURL(hodlForwardCase)}>
                {hodlForwardCase.title}
              </Link>
            </strong>
            <p>{hodlForwardCase.intro}</p>
            <Link className="more" to={resourceURL(hodlForwardCase)}>
              Learn more
            </Link>
          </li>
        ))}
      </ul>
      <Paginator
        path={page.path}
        perPage={perPage}
        pageIndex={pageIndex}
        totalCount={collection.length}
      />
    </Root>
  );
};

export default Page;
